// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-contact-js": () => import("../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-home-js": () => import("../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-join-js": () => import("../src/templates/join.js" /* webpackChunkName: "component---src-templates-join-js" */),
  "component---src-templates-recipe-js": () => import("../src/templates/recipe.js" /* webpackChunkName: "component---src-templates-recipe-js" */),
  "component---src-templates-wine-js": () => import("../src/templates/wine.js" /* webpackChunkName: "component---src-templates-wine-js" */),
  "component---src-templates-post-js": () => import("../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-page-js": () => import("../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-locations-js": () => import("../src/templates/locations.js" /* webpackChunkName: "component---src-templates-locations-js" */),
  "component---src-templates-wines-js": () => import("../src/templates/wines.js" /* webpackChunkName: "component---src-templates-wines-js" */),
  "component---src-templates-recipes-js": () => import("../src/templates/recipes.js" /* webpackChunkName: "component---src-templates-recipes-js" */),
  "component---src-templates-recipe-category-js": () => import("../src/templates/recipeCategory.js" /* webpackChunkName: "component---src-templates-recipe-category-js" */),
  "component---src-templates-posts-js": () => import("../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

